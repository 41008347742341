<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Workflow Management
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <em>
          <p class="pb-2">Manage workflows that can run a sequence of applications to orchestrate a data pipeline. This can be either an internal workflow, i.e. with applications only locally to the connector, to execute pre-processing or analysis of data. Or can be an external workflow, i.e. with applications hosted by multiple connectors, to federately execute a data pipeline. External workflows can be used for Privacy Enhancing Technologies (e.g. Secure Multi-Party Computation, Federated (Deep) Learning), but also for more traditional data sharing where the data itself can be shared.</p>
          <p class="pb-2">The model of the workflows is not standardized yet and therefore two object models are supported: the Network Model, the BIM Workflow Object Model. The Network Model is the internal model that is used by the workflow manager and contains a list of steps that are compiled into a directed acyclic graph. The BIM Workflow Object Model is a model originating from the Building and Infrastructure domain and contains more information on the input/output of steps in the workflow.</p>
          <p><strong>Note:</strong> more documentation and explanations will be added in the future.</p>
        </em>
      </card-component>
      <card-component title="Active workflows" icon="chart-sankey-variant" header-icon="reload" @header-icon-click="getWorkflows">
        <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="id"
          default-sort-direction="desc"
          :data="workflows"
        >
          <b-table-column label="Workflow ID" field="id" sortable v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column label="State" field="state" sortable v-slot="props">
            <div v-for="(state, stateName) in props.row.state" :key="props.row.id+stateName">
              {{ stateName }} -> {{ state }}
            </div>
          </b-table-column>
          <b-table-column label="Actions" v-slot="props">
            <b-icon
              icon="share"
              type="is-success"
              class="is-clickable"
              @click.native="() => {workflowInvokeModal = { isActive: true, workflow: props.row }}"
            >
            </b-icon>
            <b-icon
              icon="file-search"
              type="is-info"
              class="is-clickable"
              @click.native="showWorkflowResults(props.row.id)"
            >
            </b-icon>
            <b-icon
              icon="delete"
              type="is-danger"
              class="is-clickable"
              @click.native="deleteWorkflow(props.row.id)"
            >
            </b-icon>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No workflows present...</p>
            </div>
          </section>
        </b-table>
      </card-component>
      <card-component title="Start new workflow" icon="file-document-edit" headerColor="info" class="tile is-child">
        <form @submit.prevent="startWorkflow">
          <b-field label="Object Model" horizontal>
            <b-radio v-model="newWorkflow.type"
                name="name"
                native-value="network">
                Network Model
            </b-radio>
            <b-radio v-model="newWorkflow.type"
                name="name"
                native-value="bim">
                BIM Workflow Object model
            </b-radio>
          </b-field>
          <b-field label="Workflow model" horizontal>
            <b-input
              v-model="newWorkflow.model"
              type="textarea"
              :placeholder="workflowModelPlaceholder"
              rows="40"
              required
            />
          </b-field>
          <hr />
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary"
                  >Start workflow</b-button
                >
              </div>
              <div class="control">
                <b-button type="is-primary is-outlined" @click="()=>{newWorkflow = { type: 'network', model: null}}"
                  >Reset</b-button
                >
              </div>
            </b-field>
          </b-field>
        </form>
      </card-component>
      <b-modal :active.sync="workflowResultModal.isActive" has-modal-card>
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Workflow results</p>
                </header>
                <section class="modal-card-body">
                  <vue-json-editor v-model="workflowResultModal.result" :modes="['view', 'text']" mode="view" :show-btns="false" :expandedOnStart="true"></vue-json-editor>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="workflowResultModal.isActive = false">Close</button>
                </footer>
            </div>
        </b-modal>
      <b-modal :active.sync="workflowInvokeModal.isActive" has-modal-card>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Workflow invoke messages</p>
                </header>
                <section class="modal-card-body">
                  <b-field label="Step" v-if="workflowInvokeModal.workflow" horizontal>
                    <b-select placeholder="Network step name" v-model="invoke.step" required>
                      <option v-for="(state, step) in workflowInvokeModal.workflow.state" :key="`invoke-${workflowInvokeModal.workflow.id}-${step}`">
                        {{ step }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label="Input ID" horizontal>
                    <b-input
                      v-model="invoke.index"
                      type="number"
                      placeholder="-"
                      min="0"
                      required
                    />
                  </b-field>
                  <b-field label="Body" horizontal>
                    <b-input
                      v-model="invoke.body"
                      type="textarea"
                      placeholder="Invocation body"
                      rows="5"
                    />
                  </b-field>
                  <b-field label="Content Type" horizontal>
                    <b-input
                      v-model="invoke.contentType"
                      type="text"
                      placeholder="application/json"
                    />
                  </b-field>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" type="button" @click="invokeMessage">Invoke</button>
                    <button class="button" type="button" @click="workflowInvokeModal.isActive = false">Close</button>
                </footer>
            </div>
        </b-modal>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import { mapState } from 'vuex'
import vueJsonEditor from 'vue-json-editor'

export default {
  name: 'WorkflowManager',
  components: {
    HeroBar,
    CardComponent,
    TitleBar,
    vueJsonEditor
  },
  data () {
    return {
      isLoading: false,
      paginated: true,
      workflowModal: {
        isActive: false,
        workflow: null
      },
      workflows: [],
      workflowResultModal: {
        isActive: false,
        workflow: null,
        result: null
      },
      workflowInvokeModal: {
        isActive: false,
        workflow: null
      },
      newWorkflow: {
        type: 'network',
        model: null
      },
      invoke: {
        step: null,
        index: 0,
        body: null,
        contentType: 'application/json',
        prefixUrl: 'http://localhost:8080/router/workflow'
      }
    }
  },
  computed: {
    titleStack () {
      return ['Orchestration', 'Workflow Management']
    },
    workflowModelPlaceholder () {
      return (this.newWorkflow.type === 'network')
        ? `parties:
  - id: urn:ids:bob
    name: Bob
    type: IDS
    accessUrl: http://localhost:8080/router/workflow/1
  - id: urn:ids:dave
    name: Dave
    type: IDS
    accessUrl: http://localhost:8080/router/workflow/2
idsid: urn:ids:alice
container:
  name: localhost
  image:
    name: registry.ids.smart-connected.nl/mpc
    tag: latest
    pullSecretName: ids-registry-secret
  ports:
    - 40000/alice
  environment:
    MPC_ROLE: "main"
  configuration:
    # Example
    - filename: mpc-constants.json
      content: |-
        {
          "seed": 76543224545454234
        }
accessUrl: "http://localhost:40000/alice"
steps:
  - name: "initialize"
    input:
      - type: "http"
        endpoint: "/initialize/start"
        autoInitiate: true
        from:
          - "self"
      - type: "http"
        endpoint: "/initialize/mpc-constants"
        count: 3
        from:
          - "urn:ids:bob"
    output:
      - type: "http"
        count: 3
        to:
          - id: "urn:ids:bob"
            endpoint: "/initialize/input/1"
#      - type: "http"
#        to:
#          - id: "urn:ids:dave"
#            endpoint: "/intersect/input/0"
  - name: "learning"
    depends_on:
      - "initialize"
    output:
      - type: "http"
        to:
          - id: "urn:ids:dave"
            endpoint: "/intersect/input/0"
    input:
      - type: "http"
        endpoint: "/learning/input"
        from:
          - "urn:ids:dave"
  - name: "distributeShare"
    depends_on:
      - "learning"
    output:
      - type: "http"
        to:
          - id: "urn:ids:dave"
            endpoint: "/distributeShares/input/0"
    input:
      - type: "http"
        endpoint: "/distributeShare/input"
        from:
          - "urn:ids:dave"
#      -  TO DB
`
        : `capabilities:
  - id: 100
    name: "IFCconvertor"
    description: "converts ifc to some json"
    container:
      name: "ifc-convertor"
      image:
        name: "registry.ids.smart-connected.nl/workflow-test-app"
        tag: latest
      ports:
        - "8080"
    nodes:
      - name: IFCInput
        mandatory: true
        schema: IFC2X3
        dataType: IFC
        stepType: INPUT
        transferType: DIRECT
        # transferType: httpReference
        # transferType: filePointer
      - name: JSONOutput
        mandatory: true
        schema: fileReference
        dataType: json
        stepType: OUTPUT
        transferType: DIRECT
  - id: 200
    name: "JSONconvertor"
    description: "converts json to xml"
    container:
      name: "json-convertor"
      image:
        name: "registry.ids.smart-connected.nl/workflow-test-app"
        tag: latest
      ports:
        - "8080"
    nodes:
      - name: JSONInputA
        mandatory: true
        schema: fileReference
        dataType: json
        stepType: INPUT
        transferType: DIRECT
      - name: JSONInputB
        mandatory: true
        schema: fileReference
        dataType: json
        stepType: INPUT
        transferType: DIRECT
      - name: XMLOutput
        mandatory: true
        schema: fileReference
        dataType: xml
        stepType: OUTPUT
        transferType: DIRECT
  - id: 300
    name: "Aggregator"
    description: "aggregates xml and json to another json file"
    container:
      name: "aggregator"
      image:
        name: "registry.ids.smart-connected.nl/workflow-test-app"
        tag: latest
      ports:
        - "8080"
    nodes:
      - name: JSONInput
        mandatory: true
        schema: fileReference
        dataType: json
        stepType: INPUT
      - name: XMLInput
        mandatory: true
        schema: fileReference
        dataType: xml
        stepType: INPUT
      - name: JSONOutput
        mandatory: true
        schema: fileReference
        dataType: json
        stepType: OUTPUT

workflows:
  - id: 1
    name: testRun
    invocationFlowMaps:
      - invocation:
          - name: IFCWindowParser
            capabilityId: 100
            nodes:
              - name: IFCInput
              - name: JSONOutput
          - name: IFCDoorParser
            capabilityId: 100
            nodes:
              - name: IFCInput
              - name: JSONOutput
          - name: IFCWallParser
            capabilityId: 100
            nodes:
              - name: IFCInput
              - name: JSONOutput
          - name: JSONConvertor
            capabilityId: 200
            nodes:
              - name: JSONInputA
                requiredNode:
                  invocation: IFCWindowParser
                  node: JSONOutput
              - name: JSONInputB
                requiredNode:
                  invocation: IFCDoorParser
                  node: JSONOutput
              - name: XMLOutput
          - name: Aggregator
            capabilityId: 300
            nodes:
              - name: JSONInput
                requiredNode:
                  invocation: IFCWallParser
                  node: JSONOutput
              - name: XMLInput
                requiredNode:
                  invocation: JSONConvertor
                  node: XMLOutput
              - name: JSONOutput
`
    },
    ...mapState(['api'])
  },
  async created () {
    this.workflowInterval = setInterval(this.getWorkflows, 30000)
    await this.getWorkflows()
  },
  methods: {
    async getWorkflows () {
      try {
        const workflows = await this.api.get('/workflow')
        this.workflows = workflows.data
      } catch (e) {
        clearInterval(this.workflowInterval)
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            clearInterval(this.workflowInterval)
            this.getWorkflows()
            this.workflowInterval = setInterval(this.getWorkflows, 30000)
          }
        })
        console.log(e)
      }
    },
    async deleteWorkflow (workflowId) {
      this.$buefy.dialog.confirm({
        title: 'Delete resource',
        message: `Are you sure you want to delete the workflow ${workflowId}?`,
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await this.api.delete(
              `/workflow/${encodeURIComponent(workflowId)}`
            )
            await this.getWorkflows()
          } catch (e) {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              position: 'is-bottom'
            })
            console.log(e)
          }
        }
      })
    },
    async startWorkflow () {
      try {
        const url = (this.newWorkflow.type === 'network') ? '/workflow' : '/workflow/group'
        await this.api.post(url, this.newWorkflow.model, {
          headers: {
            'Content-Type': 'text/yaml'
          }
        })
        this.$buefy.toast.open({
          message: 'Successfully started the workflow',
          type: 'is-success',
          position: 'is-bottom'
        })
        this.newWorkflow = {
          type: 'network',
          model: null
        }
        await this.getWorkflows()
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    },
    async showWorkflowResults (workflowId) {
      try {
        const workflowResults = await this.api.get(`/workflow/${encodeURIComponent(workflowId)}/results`)
        this.workflowResultModal = {
          isActive: true,
          workflow: this.workflows.find(workflow => workflow.id === workflowId),
          result: workflowResults.data
        }
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    },
    async invokeMessage () {
      try {
        if (this.invoke.body) {
          await this.api.post(`/workflow/invoke/${this.workflowInvokeModal.workflow.id}/${this.invoke.step}/${this.invoke.index}`, this.invoke.body, {
            headers: {
              'Content-Type': this.invoke.contentType
            }
          })
        } else {
          await this.api.post(`/workflow/invoke/${this.workflowInvokeModal.workflow.id}/${this.invoke.step}/${this.invoke.index}`)
        }
        this.$buefy.toast.open({
          message: `Successfully invoked input ${this.invoke.index} of step ${this.invoke.step} in workflow ${this.workflowInvokeModal.workflow.id}`,
          type: 'is-success',
          position: 'is-bottom'
        })
        this.workflowInvokeModal = {
          isActive: false,
          workflow: null
        }
        this.invoke = {
          step: null,
          index: 0,
          body: null,
          contentType: 'application/json',
          prefixUrl: 'http://localhost:8080/router/workflow'
        }
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    }
  }
}
</script>
